const config = {
    firebase: {
        apiKey: "AIzaSyCzT4tTeNmjwqh-VMDmzeLGBUQw0vv1sdw",
        authDomain: "vide-home.firebaseapp.com",
        databaseURL: "https://vide-home-default-rtdb.firebaseio.com",
        projectId: "vide-home",
        storageBucket: "vide-home.appspot.com",
        messagingSenderId: "738175575036",
        appId: "1:738175575036:web:527123c738941b51a24879",
        measurementId: "G-GY3G6SVNT3"
    }
}

export default config;
